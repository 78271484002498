"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { useElements } from '../useElements.mjs';

function useAppEventSubscriptions(query) {
  const { ...rest } = useElements(
    "app-event-subscriptions",
    query
  );
  return {
    appEventSubscriptions: rest.items,
    ...rest
  };
}

export { useAppEventSubscriptions };

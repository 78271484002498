"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

const TAG_MARK_END = "}";
const TAG_MARK_START = "{";
const regExpStr = `(${TAG_MARK_START}(?:[^${TAG_MARK_START}${TAG_MARK_END}\\s]*)${TAG_MARK_END})`;
const regExpTag = new RegExp(regExpStr, "gm");
function getTagString(id) {
  return `${TAG_MARK_START}${id}${TAG_MARK_END}`;
}

export { TAG_MARK_END, TAG_MARK_START, getTagString, regExpTag };

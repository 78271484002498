"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import React, { createContext, useContext, useState, useRef, useEffect, useId } from 'react';
import { Portal } from '@ark-ui/react';

const BoundaryContext = createContext("");
const BOUNDARY_DATA_KEY = "data-floating-portal-boundary";
function FloatingPortalBoundary({
  children,
  id,
  ...props
}) {
  const generatedId = useId();
  if (!React.isValidElement(children)) {
    if (process.env.NODE_ENV === "development") {
      console.error(
        "Error: FloatingPortalBoundary must have exactly one child element"
      );
    }
    return /* @__PURE__ */ jsx(BoundaryContext.Provider, { value: id || generatedId, children });
  }
  return /* @__PURE__ */ jsx(BoundaryContext.Provider, { value: id || generatedId, children: React.cloneElement(children, {
    [BOUNDARY_DATA_KEY]: id || generatedId,
    ...props,
    ...children.props
  }) });
}
function BoundedPortal({
  children,
  usePortal = true
}) {
  const boundaryId = useContext(BoundaryContext);
  const [container, setContainer] = useState(null);
  const containerRef = useRef(null);
  useEffect(() => {
    const container2 = usePortal instanceof HTMLElement ? usePortal : document.querySelector(
      `[${BOUNDARY_DATA_KEY}="${boundaryId}"]`
    );
    setContainer(container2);
  }, []);
  containerRef.current = container;
  if (!containerRef.current) {
    return /* @__PURE__ */ jsx(Portal, { disabled: !usePortal, children });
  }
  return /* @__PURE__ */ jsx(Portal, { container: containerRef, disabled: !usePortal, children });
}

export { BoundedPortal, FloatingPortalBoundary };

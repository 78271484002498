"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import { INSERT_EDITOR_TAG_COMMAND } from './plugins/commands.mjs';

const $getEditorNodesSummary = () => {
  const children = $getRoot().getChildren();
  const rootParagraph = children[0];
  return {
    textNodes: rootParagraph.getAllTextNodes().length,
    otherNodes: rootParagraph.getChildrenSize() - rootParagraph.getAllTextNodes().length
  };
};
const useEditor = () => {
  const [editor] = useLexicalComposerContext();
  function pasteTag({
    value,
    autoOpen,
    allowEditorSearchClear = false
  }) {
    if (allowEditorSearchClear) {
      editor.update(() => {
        const { otherNodes } = $getEditorNodesSummary();
        if (otherNodes === 0) {
          $getRoot().clear();
        }
      });
    }
    editor.dispatchCommand(INSERT_EDITOR_TAG_COMMAND, {
      id: (/* @__PURE__ */ new Date()).getTime().toString(),
      value,
      autoOpen
    });
  }
  return { editor, pasteTag };
};

export { useEditor };

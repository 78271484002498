import { useState } from 'react'
import { Org, Workspace } from '@integration-app/sdk'
import { useRouter } from 'next/router'
import { TbArrowRight } from 'react-icons/tb'

import useApi from 'hooks/useApi'
import { Flex } from 'styled-system/jsx'
import { Button } from 'ui-kit/button'
import { Heading } from 'ui-kit/heading'
import { Icon } from 'ui-kit/icon'
import { Text } from 'ui-kit/text'

import useAuth from '../../contexts/auth'
import { Combobox, SelectItemType } from '../Select'

export function SelectWorkspaceScreen({ wsId }: { wsId?: string }) {
  const router = useRouter()
  const { self } = useAuth()
  const { apiClient, setImpersonationToken } = useApi()
  const [searchValue, setSearchValue] = useState('')

  const isAdmin: boolean = self.user?.isAdmin ?? false
  const workspaces: Workspace[] = self?.workspaces ?? []
  const orgs: Org[] = self?.orgs ?? []

  const [selectedWsItem, setSelectedWsItem] = useState<
    SelectItemType | undefined
  >(convertWorkspaceToComboboxItem(workspaces[0], orgs))

  async function impersonateWorkspaceOwner(workspaceId: string) {
    const { data } = await apiClient.get(
      `console-admin/impersonate-workspace-owner/${workspaceId}`,
    )
    const { token } = data

    setImpersonationToken(token)
    window.location.reload()
  }

  const comboboxItems = convertWorkspaceToComboboxItems(
    workspaces,
    orgs,
    searchValue,
  )

  return (
    <>
      <Flex
        flexDirection={'column'}
        alignItems={'start'}
        gap={3}
        width={'sm'}
        mx={'auto'}
        mt={44}
      >
        {wsId && (
          <Heading as={'h1'} fontWeight={'normal'} size={'2xl'} mt={0} mb={6}>
            Workspace with id {wsId} not found.
          </Heading>
        )}

        {!wsId && (
          <Heading as={'h1'} fontWeight={'normal'} size={'2xl'} mt={0} mb={6}>
            Please select a workspace to continue.
          </Heading>
        )}

        {workspaces.length && (
          <>
            <Text color='fg.muted' m={0}>
              You can switch to one of the available workspaces:
            </Text>

            <Flex width={'full'} gap='2'>
              <Combobox
                minWidth={'fit-content'}
                items={comboboxItems}
                variant={'outline'}
                autoFocus={false}
                value={selectedWsItem}
                onValueChange={setSelectedWsItem}
                onSearchInputChange={setSearchValue}
              />
              <Button
                variant={'solid'}
                minWidth={'fit-content'}
                onClick={() => void router.push(`/w/${selectedWsItem?.value}`)}
              >
                Go to workspace{' '}
                <Icon>
                  <TbArrowRight />
                </Icon>
              </Button>
            </Flex>
          </>
        )}

        {isAdmin && wsId && (
          <Flex flexDirection={'column'} alignItems={'start'} gap={3} mt={4}>
            <Text color='fg.muted' m={0}>
              Or impersonate workspace owner:
            </Text>
            <Button
              variant={'solid'}
              size={'sm'}
              colorPalette={'error'}
              onClick={() => impersonateWorkspaceOwner(wsId)}
            >
              Impersonate Owner
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  )
}

function convertWorkspaceToComboboxItems(
  workspaces: Workspace[],
  orgs: Org[] | undefined,
  searchValue: string,
): SelectItemType[] {
  const filteredWorkspaces = workspaces
    .filter((ws) => filterWorkspace(ws, searchValue))
    .map((ws) => convertWorkspaceToComboboxItem(ws, orgs))
    .filter(Boolean) as SelectItemType[]

  return (
    orgs?.reduce((acc, org) => {
      const orgWorkspaces = filteredWorkspaces
        .filter((ws) => ws.orgName === org.name)
        .filter(Boolean) as SelectItemType[]

      if (orgWorkspaces.length) {
        acc.push({ label: org.name, value: '', disabled: true })
        acc.push(...orgWorkspaces)
      }

      return acc
    }, [] as SelectItemType[]) ?? []
  )
}

function filterWorkspace(item: Workspace, searchValue: string): boolean {
  if (!searchValue) return true
  const search = searchValue.toLowerCase()

  return (
    (!!item.name && item.name.toLowerCase().includes(search)) ||
    (!!item.id && item.id.toString().includes(search))
  )
}

function convertWorkspaceToComboboxItem(
  ws: Workspace | undefined,
  orgs: Org[] | undefined,
): SelectItemType | undefined {
  if (!ws) return undefined
  return {
    label: ws?.name,
    value: ws?.id,
    orgName: orgs?.find((o) => o.id === ws.orgId)?.name,
  }
}

"use strict";
"use client";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { Avatar } from '@ark-ui/react/avatar';
export { AvatarContext as Context } from '@ark-ui/react/avatar';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/conditions.mjs';
import '../../styled-system/css/css.mjs';
import { avatar } from '../../styled-system/recipes/avatar.mjs';
import { createStyleContext } from './utils/create-style-context.mjs';

const { withProvider, withContext } = createStyleContext(avatar);
withProvider(Avatar.RootProvider, "root");
const Root = withProvider(Avatar.Root, "root");
const Fallback = withContext(Avatar.Fallback, "fallback");
const Image = withContext(Avatar.Image, "image");

export { Fallback, Image, Root };

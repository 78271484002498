"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { createCommand } from 'lexical';

const INSERT_EDITOR_TAG_COMMAND = createCommand();

export { INSERT_EDITOR_TAG_COMMAND };

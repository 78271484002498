"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { IconLayoutGridAdd } from '@tabler/icons-react';

function SvgIconUnifiedInterfaces({ ...props }) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      ...props,
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M15.333 6.188H8c-1.013 0-1.833.82-1.833 1.833v7.333c0 1.013.82 1.834 1.833 1.834h7.333c1.013 0 1.833-.821 1.833-1.834V8.021c0-1.013-.82-1.833-1.833-1.833ZM9.5 11.879h4.714M11.857 9.521v4.714"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M12.834 6.188V4.52a1.667 1.667 0 0 0-1.667-1.666H4.5A1.667 1.667 0 0 0 2.833 4.52v6.667A1.667 1.667 0 0 0 4.5 12.855h1.667"
          }
        )
      ]
    }
  );
}
function SvgIconUnifiedInterface({ ...props }) {
  return /* @__PURE__ */ jsx(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      ...props,
      children: /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          d: "M13.667 4.521H6.333c-1.012 0-1.833.82-1.833 1.833v7.334c0 1.012.82 1.833 1.833 1.833h7.334c1.012 0 1.833-.82 1.833-1.833V6.354c0-1.012-.82-1.833-1.833-1.833ZM7.833 9.912h4.715M10.191 7.555v4.714"
        }
      )
    }
  );
}
function SvgIconApp({ ...props }) {
  const style = Object.assign({}, props?.style ?? {}, {
    transform: "scaleY(-1)"
  });
  return /* @__PURE__ */ jsx(IconLayoutGridAdd, { ...props, style });
}
function SvgIconFieldMappings({ ...props }) {
  return /* @__PURE__ */ jsx(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      stroke: "currentColor",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M7.3 12h-4a1 1 0 0 0-1 1v1.6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V13a1 1 0 0 0-1-1ZM12 13.7H8M16.7 12h-4a1 1 0 0 0-1 1v1.6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V13a1 1 0 0 0-1-1ZM7.3 4.5h-4a1 1 0 0 0-1 1v1.6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5.5a1 1 0 0 0-1-1ZM8 6.3h4M16.7 4.5h-4a1 1 0 0 0-1 1v1.6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5.5a1 1 0 0 0-1-1Z" })
    }
  );
}
const SvgIconFieldMapping = SvgIconFieldMappings;
function SvgIconFlows({ ...props }) {
  return /* @__PURE__ */ jsx(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      stroke: "currentColor",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M14.5 12.8v-.777a2 2 0 0 0-2-2h-5a2 2 0 0 0-2 2v.778m4.512-5.5v2.826M12 3H8a1 1 0 0 0-1 1v1.6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1ZM7.5 13.5h-4a1 1 0 0 0-1 1v1.6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.6a1 1 0 0 0-1-1ZM16.5 13.5h-4a1 1 0 0 0-1 1v1.6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.6a1 1 0 0 0-1-1Z" })
    }
  );
}
const SvgIconFlow = SvgIconFlows;
function SvgIconDataSources({ ...props }) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      ...props,
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M5.47 5.969c-1.868-.405-3.125-1.163-3.125-2.03 0-1.294 2.798-2.343 6.249-2.343 3.451 0 6.249 1.049 6.249 2.343v.742"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinejoin: "round",
            d: "M2.345 3.94v4.686c0 .621.659 1.217 1.83 1.657.313.117.655.22 1.02.309"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinejoin: "round",
            d: "M2.345 8.626v4.686c0 .622.659 1.218 1.83 1.657a8.93 8.93 0 0 0 1.024.31"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M5.157 11.438v4.686c0 .621.659 1.217 1.83 1.657 1.172.44 2.762.686 4.419.686 1.657 0 3.246-.247 4.418-.686 1.172-.44 1.83-1.036 1.83-1.657v-4.686"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M5.157 6.751v4.686c0 .622.659 1.218 1.83 1.657 1.172.44 2.762.687 4.419.687 1.657 0 3.246-.247 4.418-.687 1.172-.439 1.83-1.035 1.83-1.656V6.75"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M11.406 9.094c3.45 0 6.248-1.049 6.248-2.343s-2.797-2.343-6.248-2.343S5.157 5.457 5.157 6.75s2.798 2.343 6.249 2.343Z"
          }
        )
      ]
    }
  );
}
function SvgIconObjectLinks({ ...props }) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      ...props,
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M8.71 13.022v4.065L5.096 19.12l-3.614-2.033v-4.065M5.096 15.054l3.613-2.032M5.096 15.054v4.066M5.096 15.054l-3.614-2.032M18.518 7.02v4.066l-3.614 2.032-3.614-2.032V7.02M14.903 9.053l3.614-2.033M14.903 9.053v4.065M14.904 9.053 11.29 7.02"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M5.096 6.924 8.71 8.956v4.066l-3.614 2.032-3.614-2.032V8.956l3.614-2.032ZM5.096 10.989l3.613-2.033M5.096 10.989v4.065M5.096 10.989 1.482 8.956M14.904.922l3.614 2.033V7.02l-3.614 2.033L11.29 7.02V2.955L14.904.922ZM14.903 4.988l3.614-2.033M14.903 4.988v4.065M14.904 4.988 11.29 2.955"
          }
        ),
        /* @__PURE__ */ jsx("path", { stroke: "currentColor", d: "M8.556 12.55 11.47 10.9" })
      ]
    }
  );
}
function SvgIconObjectLink({ ...props }) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      ...props,
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M5.096 8.956 8.71 10.99v4.065l-3.614 2.033-3.614-2.033V10.99l3.614-2.033ZM5.096 13.022l3.613-2.033M5.096 13.022v4.065M5.096 13.022l-3.614-2.033M14.904 2.955l3.613 2.033v4.065l-3.613 2.033-3.614-2.033V4.988l3.614-2.033ZM14.903 7.02l3.614-2.032M14.903 7.02v4.066M14.904 7.02 11.29 4.988"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            d: "M8.345 10.808 11.29 9.15"
          }
        )
      ]
    }
  );
}
function SvgIconDataSchema({ ...props }) {
  return /* @__PURE__ */ jsx(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      ...props,
      children: /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          d: "M18 10.022a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM6 15.02a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM6 5.021a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM6 15.022h2a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H6M10 10.021h4"
        }
      )
    }
  );
}
function SvgIconDataSchemas({ ...props }) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      ...props,
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M18.49 11.021a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM5.51 4.021a2 2 0 1 0-2 2"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M7.51 6.021a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM5.51 14.021a2 2 0 1 0-2 2M7.51 16.021h2a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-2"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M7.51 16.021a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM11.51 11.021h2.9"
          }
        )
      ]
    }
  );
}
function SvgIconAppEventSubscriptions({ ...props }) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "none",
      ...props,
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinejoin: "round",
            d: "M9.417 7.354V1.521L2.75 10.688h4.392"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M12.25 3.521v5.833h5l-6.667 9.167v-5.833h-5L12.25 3.52Z"
          }
        )
      ]
    }
  );
}

export { SvgIconApp, SvgIconAppEventSubscriptions, SvgIconDataSchema, SvgIconDataSchemas, SvgIconDataSources, SvgIconFieldMapping, SvgIconFieldMappings, SvgIconFlow, SvgIconFlows, SvgIconObjectLink, SvgIconObjectLinks, SvgIconUnifiedInterface, SvgIconUnifiedInterfaces };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useState, useContext, useRef, useEffect } from 'react';

const DropdownStackContext = createContext({
  isNested: false,
  stack: [],
  setStack: () => {
  }
});
const DropdownStackContextProvider = ({
  children,
  editorRef
}) => {
  const [stack, setStack] = useState([]);
  const {
    isNested: hasNestedDropdownsContext,
    stack: parentStack,
    setStack: setParentStack
  } = useContext(DropdownStackContext);
  if (hasNestedDropdownsContext) {
    return /* @__PURE__ */ jsx(
      DropdownStackContext.Provider,
      {
        value: {
          isNested: true,
          stack: parentStack,
          setStack: setParentStack,
          editorRef
        },
        children
      }
    );
  }
  return /* @__PURE__ */ jsx(
    DropdownStackContext.Provider,
    {
      value: {
        isNested: true,
        stack,
        setStack,
        editorRef
      },
      children
    }
  );
};
const useDropdownStackContext = (dropdownRef) => {
  const { isNested, stack, setStack, editorRef } = useContext(DropdownStackContext);
  const mutableRefContainer = useRef(dropdownRef);
  useEffect(() => {
    if (isNested) {
      setStack((prev) => [...prev, mutableRefContainer?.current]);
    } else {
      setStack([mutableRefContainer?.current]);
    }
    return () => {
      setStack(
        (prev) => prev.filter((record) => record !== mutableRefContainer?.current)
      );
    };
  }, []);
  function isClickWithinStackItem(event) {
    const isClickOnCurrentEditor = editorRef?.current?.firstChild == event.target;
    const stackStartedFromElement = stack.slice(
      stack.indexOf(mutableRefContainer.current)
    );
    const isClickInStack = stackStartedFromElement.some((ref) => {
      return !!(ref?.current && ref.current.contains(event.target));
    });
    return isClickInStack || isClickOnCurrentEditor;
  }
  return { isClickWithinStackItem };
};

export { DropdownStackContextProvider, useDropdownStackContext };

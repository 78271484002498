"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { NODE_DATA_ATTR } from '../nodes/EditorTagNode.mjs';

function isClickOnEditorCustomTagComponent(element, rootElement) {
  if (!element) {
    return false;
  }
  if (element === rootElement) {
    return false;
  }
  return isElementEditorCustomTagComponent(element) || isClickOnEditorCustomTagComponent(element?.parentElement, rootElement);
}
function isClickOnEditorComponent(element, rootElement) {
  if (!element) {
    return false;
  }
  if (element === rootElement) {
    return false;
  }
  return isElementEditorComponent(element) || isClickOnEditorComponent(element?.parentElement, rootElement);
}
function isElementEditorCustomTagComponent(element) {
  return element.hasAttribute(NODE_DATA_ATTR);
}
function isElementEditorComponent(element) {
  return element.hasAttribute("data-editor-container");
}

export { isClickOnEditorComponent, isClickOnEditorCustomTagComponent };

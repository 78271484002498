"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { useEffect, useRef } from 'react';
import { DROPDOWN_NAVIGATOR_ID, DROPDOWN_SEARCH_INPUT_ID } from '../../components/DropdownUI/constants.mjs';

var EVENT_TYPE = /* @__PURE__ */ ((EVENT_TYPE2) => {
  EVENT_TYPE2["OPTION_DOWN"] = "option_down";
  EVENT_TYPE2["OPTION_UP"] = "option_up";
  EVENT_TYPE2["OPTION_RIGHT"] = "option_right";
  EVENT_TYPE2["OPTION_LEFT"] = "option_left";
  EVENT_TYPE2["OPTION_SELECT"] = "option_select";
  EVENT_TYPE2["FORM_SUBMIT"] = "form_submit";
  return EVENT_TYPE2;
})(EVENT_TYPE || {});
const eventEmitter = {
  _events: {
    ["option_down" /* OPTION_DOWN */]: {},
    ["option_up" /* OPTION_UP */]: {},
    ["option_right" /* OPTION_RIGHT */]: {},
    ["option_left" /* OPTION_LEFT */]: {},
    ["option_select" /* OPTION_SELECT */]: {},
    ["form_submit" /* FORM_SUBMIT */]: {}
  },
  _isExist(event, scopedComboboxId) {
    return !!this?._events?.[event]?.[scopedComboboxId];
  },
  dispatch(event, scopedComboboxId) {
    if (!this._isExist(event, scopedComboboxId)) return;
    this._events[event][scopedComboboxId].forEach(
      (callback) => callback(scopedComboboxId)
    );
  },
  subscribe(event, scopedComboboxId, callback) {
    if (!this._isExist(event, scopedComboboxId)) {
      this._events[event][scopedComboboxId] = [];
    }
    if (callback) {
      this._events[event][scopedComboboxId].push(callback);
    }
  },
  unsubscribe(event, scopedComboboxId, callback) {
    if (!this._isExist(event, scopedComboboxId)) return;
    if (callback) {
      this._events[event][scopedComboboxId] = this._events[event][scopedComboboxId].filter((cb) => cb !== callback);
    }
  }
};
function useOptionListKeyNavDispatch(ref = null, scopedComboboxId) {
  return useKeyPressDispatch(
    ref,
    (e) => handleOptionListKeyNavigation(e, scopedComboboxId)
  );
}
function useKeyPressDispatch(ref, handler = () => {
}) {
  const savedHandler = useRef(handler);
  const targetElement = ref instanceof Element ? ref : ref?.current;
  return useEffect(() => {
    if (!targetElement || !targetElement?.addEventListener) {
      return () => {
      };
    }
    targetElement.addEventListener("keydown", savedHandler.current);
    return () => {
      targetElement.removeEventListener("keydown", savedHandler.current);
    };
  }, [targetElement, savedHandler]);
}
function useKeyboardEventSubscribe({
  scopedComboboxId,
  event,
  callback
}) {
  return useEffect(() => {
    eventEmitter.subscribe(event, scopedComboboxId, callback);
    return () => {
      eventEmitter.unsubscribe(event, scopedComboboxId, callback);
    };
  }, [event, callback]);
}
const dispatch = (event, scopedComboboxId) => eventEmitter.dispatch(event, scopedComboboxId);
const handleOptionListKeyNavigation = (e, scopedComboboxId) => {
  if (!(e.target instanceof HTMLElement)) {
    return;
  }
  const targetId = e.target.getAttribute("data-navigation-emitter-id") || "";
  const allowedTargets = [DROPDOWN_NAVIGATOR_ID, DROPDOWN_SEARCH_INPUT_ID];
  if (!allowedTargets.includes(targetId)) {
    return;
  }
  switch (e.key) {
    case "Enter":
      if (!e.shiftKey) {
        dispatch("option_select" /* OPTION_SELECT */, scopedComboboxId);
        e.preventDefault();
        e.stopPropagation();
      }
      break;
    case "ArrowUp":
      dispatch("option_down" /* OPTION_DOWN */, scopedComboboxId);
      break;
    case "ArrowDown":
      dispatch("option_up" /* OPTION_UP */, scopedComboboxId);
      break;
    case "ArrowRight":
      if (canHandleHorizontalNavigation(e.target)) {
        dispatch("option_right" /* OPTION_RIGHT */, scopedComboboxId);
      }
      break;
    case "ArrowLeft":
      if (canHandleHorizontalNavigation(e.target)) {
        dispatch("option_left" /* OPTION_LEFT */, scopedComboboxId);
      }
      break;
  }
};
const canHandleHorizontalNavigation = (target) => {
  const targetId = target.getAttribute("data-navigation-emitter-id") || "";
  if (targetId === DROPDOWN_NAVIGATOR_ID) {
    return true;
  }
  const isInput = target instanceof HTMLInputElement;
  if (!isInput) {
    return true;
  }
  const hasValue = !!target.value;
  return targetId === DROPDOWN_SEARCH_INPUT_ID && !hasValue;
};

export { EVENT_TYPE, useKeyboardEventSubscribe, useOptionListKeyNavDispatch };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { ark } from '@ark-ui/react/factory';
import { styled } from '../../styled-system/jsx/factory.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import 'react';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';
import '../../styled-system/css/conditions.mjs';
import { button } from '../../styled-system/recipes/button.mjs';

const IconButton = styled(ark.button, button, {
  defaultProps: { px: "0" }
});

export { IconButton };

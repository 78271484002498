"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useIntegration(idOrKey) {
  const { item: integration, ...rest } = useElement(
    WorkspaceElementType.Integration,
    idOrKey,
    (integrationApp) => idOrKey ? integrationApp.integration(idOrKey) : void 0
  );
  return { integration, ...rest };
}

export { useIntegration };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../styled-system/jsx/box.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';

const Panel = forwardRef(
  ({ className, children, ...props }, ref) => {
    const { panel } = dropdownRecipe();
    return /* @__PURE__ */ jsx(Box, { className: clsx(panel, className), ref, ...props, children });
  }
);

export { Panel };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { getSchemaFromValue, getReferenceCollectionPointerForSchema } from '@integration-app/sdk';
import { clsx } from 'clsx';
import useSWR from 'swr';
import { SvgIcon } from '../../SvgIcon/index.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { useIntegrationAppConnection } from '../../../contexts/integration-app-connection-context.mjs';
import { useIntegrationApp } from '../../../contexts/integration-app-context.mjs';
import { css } from '../../../styled-system/css/css.mjs';
import '../../../styled-system/helpers.mjs';
import { ComboBoxTag, getClassFromSchema } from '../../ComboBoxElements/ComboBoxTag.mjs';
import { TagPlaceholder } from '../../Tag/TagPlaceholder.mjs';
import { Truncate } from '../../Truncate/index.mjs';
import { CUSTOM_ARRAY_VALUE_LABEL, CUSTOM_OBJECT_VALUE_LABEL } from '../options-factories/custom-field.mjs';

const ComboBoxSchemaValue = forwardRef(
  ({
    value,
    schema: providedSchema,
    variablesSchema,
    className,
    disabled,
    ...props
  }, ref) => {
    let valueElement;
    const schema = providedSchema?.type ? providedSchema : getSchemaFromValue(value, variablesSchema);
    const refCollection = getReferenceCollectionPointerForSchema(schema);
    if (refCollection) {
      return /* @__PURE__ */ jsx(ReferenceCollectionValue, { schema, value });
    }
    if (schema?.referenceRecords) {
      const selectedRecord = schema.referenceRecords.find((r) => r.id == value);
      if (selectedRecord?.name) {
        valueElement = /* @__PURE__ */ jsx(Truncate, { children: selectedRecord.name });
      }
    }
    if (!valueElement) {
      switch (schema?.type) {
        case "boolean":
          valueElement = value === void 0 ? void 0 : value ? /* @__PURE__ */ jsx(Fragment, { children: "Yes" }) : /* @__PURE__ */ jsx(Fragment, { children: "No" });
          break;
        case "object":
          if (Object.keys(schema?.properties ?? {}).length === 0) {
            valueElement = /* @__PURE__ */ jsx(Truncate, { children: CUSTOM_OBJECT_VALUE_LABEL });
          }
          break;
        case "array":
          valueElement = /* @__PURE__ */ jsx(Truncate, { children: CUSTOM_ARRAY_VALUE_LABEL });
          break;
        case "string":
        case "number":
        case "integer":
        default:
          if (value !== void 0) {
            valueElement = /* @__PURE__ */ jsx(Truncate, { children: value?.toString() });
          }
      }
    }
    if (valueElement) {
      return /* @__PURE__ */ jsx(
        ComboBoxTag,
        {
          className: clsx(className, getClassFromSchema(schema)),
          ref,
          disabled,
          ...getIconProps(schema),
          ...props,
          children: valueElement
        }
      );
    } else {
      return /* @__PURE__ */ jsx(TagPlaceholder, { ref, ...props });
    }
  }
);
ComboBoxSchemaValue.displayName = "ComboBoxSchemaValue";
function getIconProps(schema) {
  switch (schema?.type) {
    case "string":
      return {
        icon: SvgIconType.SchemaType_string
      };
    case "boolean":
      return {
        icon: SvgIconType.SchemaType_boolean
      };
    case "number":
    case "integer":
      return {
        icon: SvgIconType.Braces
      };
    default:
      return void 0;
  }
}
function ReferenceCollectionValue({
  value,
  schema
}) {
  const client = useIntegrationApp();
  const { connectionId } = useIntegrationAppConnection();
  const strValue = value?.toString() ?? "";
  const key = `reference-value-${connectionId}-${strValue}`;
  const pointer = getReferenceCollectionPointerForSchema(
    schema
  );
  const { data: findResponse, isLoading } = useSWR(client && connectionId ? key : void 0, async () => {
    try {
      return await client.connection(connectionId).dataCollection(pointer.key, pointer.parameters).findById({ id: value });
    } catch {
      return void 0;
    }
  });
  const record = findResponse?.record;
  if (isLoading) {
    return /* @__PURE__ */ jsxs(
      ComboBoxTag,
      {
        className: css({
          gap: "2"
        }),
        children: [
          /* @__PURE__ */ jsx("span", { children: record?.name || strValue }),
          /* @__PURE__ */ jsx(
            SvgIcon,
            {
              type: SvgIconType.Loader,
              className: css({ w: "4", aspectRatio: "aspect-square" })
            }
          )
        ]
      }
    );
  }
  if (record?.name) {
    return /* @__PURE__ */ jsx(ComboBoxTag, { children: record.name });
  }
  return /* @__PURE__ */ jsx(ComboBoxTag, { children: strValue });
}

export { ComboBoxSchemaValue };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { forwardRef, Fragment as Fragment$1 } from 'react';
import { getIconUriForLocator, getNameComponentsForLocator, getFullNameForLocator } from '@integration-app/sdk';
import { clsx } from 'clsx';
import classes from './styles.module.css.mjs';
import { ComboBoxTag } from '../../ComboBoxElements/ComboBoxTag.mjs';
import { Tag } from '../../Tag/Tag.mjs';
import '../../Tag/TagPlaceholder.mjs';
import { SegmentSeparator } from '../../Tag/SegmentSeparator.mjs';

const VarFormula = forwardRef(
  (props, ref) => {
    const {
      formula,
      variablesSchema,
      // We do not use the `optionFactories` prop, but we need to remove it from restProps
      optionFactories: _of,
      ...restProps
    } = props;
    const iconUri = getIconUriForLocator(variablesSchema, formula.locator);
    const nameComponents = getNameComponentsForLocator(
      variablesSchema,
      formula.locator
    );
    if (nameComponents === void 0) {
      return /* @__PURE__ */ jsx(ComboBoxTag, { invalid: true, tooltip: "Source variable doesn't exist", children: formula.locator });
    }
    const nameComponentTags = nameComponents.map((t, i) => /* @__PURE__ */ jsx("span", { className: classes.name, children: t }, i));
    if (iconUri) {
      nameComponentTags.unshift(/* @__PURE__ */ jsx(Tag.Image, { src: iconUri }));
    }
    const fullName = getFullNameForLocator(variablesSchema, formula.locator) || "Unknown Var";
    return /* @__PURE__ */ jsx(ComboBoxTag, { ref, disabled: props.disabled, ...restProps, children: /* @__PURE__ */ jsx(
      VarFormulaName,
      {
        nameComponents: nameComponentTags,
        fullName
      }
    ) });
  }
);
VarFormula.displayName = "VarFormula";
function VarFormulaName({
  nameComponents,
  fullName
}) {
  if (nameComponents.length === 0) {
    return /* @__PURE__ */ jsx(Fragment, { children: "Error: undefined" });
  }
  if (nameComponents.length <= 2) {
    const reducesComponents = nameComponents.length === 1 ? [nameComponents] : nameComponents.reduce((prev, curr) => [
      prev,
      /* @__PURE__ */ jsx(SegmentSeparator, {}),
      curr
    ]);
    return /* @__PURE__ */ jsx("span", { className: classes.valueRef, title: fullName, children: reducesComponents.map((segment, i) => /* @__PURE__ */ jsx(Fragment$1, { children: segment }, i)) });
  }
  const {
    0: first,
    [nameComponents.length - 1]: last,
    ...rest
  } = nameComponents;
  const middle = Object.values(rest);
  return /* @__PURE__ */ jsxs(
    "span",
    {
      className: clsx(classes.valueRef, classes.valueRefTruncate),
      title: fullName,
      children: [
        /* @__PURE__ */ jsx("span", { className: classes.visibleSegment, children: first }),
        /* @__PURE__ */ jsx("span", { className: classes.truncatedSegment, children: middle.map((segment, idx) => [/* @__PURE__ */ jsx(SegmentSeparator, {}, idx), segment]).flat().map((segment, i) => /* @__PURE__ */ jsx(Fragment$1, { children: segment }, i)) }),
        /* @__PURE__ */ jsxs("span", { className: classes.visibleSegment, children: [
          /* @__PURE__ */ jsx(SegmentSeparator, {}),
          last
        ] })
      ]
    }
  );
}

export { VarFormula as default };

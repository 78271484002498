"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { styled } from '../styled-system/jsx/factory.mjs';
import '../styled-system/jsx/is-valid-prop.mjs';
import '../styled-system/helpers.mjs';
import '../styled-system/css/css.mjs';
import '../styled-system/patterns/visually-hidden.mjs';
import { Spinner as Spinner$1 } from './styled/spinner.mjs';

const Spinner = forwardRef(
  (props, ref) => {
    const { label = "Loading...", ...rest } = props;
    return /* @__PURE__ */ jsx(
      Spinner$1,
      {
        ref,
        borderBottomColor: "transparent",
        borderLeftColor: "transparent",
        ...rest,
        children: label && /* @__PURE__ */ jsx(styled.span, { srOnly: true, children: label })
      }
    );
  }
);
Spinner.displayName = "Spinner";

export { Spinner };

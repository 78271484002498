"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { useState, useRef, useEffect } from 'react';

function useThrottle(value, interval = 500) {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastUpdated = useRef();
  useEffect(() => {
    const now = Date.now();
    if (lastUpdated.current && now >= lastUpdated.current + interval) {
      lastUpdated.current = now;
      setThrottledValue(value);
    } else {
      const id = window.setTimeout(() => {
        lastUpdated.current = now;
        setThrottledValue(value);
      }, interval);
      return () => window.clearTimeout(id);
    }
    return () => {
    };
  }, [value, interval]);
  return throttledValue;
}

export { useThrottle };

/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import styleInject from 'style-inject';

var css_248z = "\n  .Editor-module_editor__hh8sg p {\n    line-height: 2rem !important;\n    min-height: 2rem !important;\n    align-items: center !important;\n    margin: 0px !important;\n    padding: 0px !important;\n  }\n/*\n    I could not get this selector to work with Panda CSS in Lexical editor\n    It's needed to remove fake placeholder when something is typed\n  */\n.Editor-module_editor__hh8sg p:not(:has( > br:only-child))::before {\n    content: none;\n  }\n.Editor-module_editor__hh8sg [data-editor-container='true'] {\n    min-width: 7rem;\n  }\n.Editor-module_editor__hh8sg [data-editor-container='true']:focus {\n    outline: 2px solid transparent;\n    outline-offset: 2px;\n}\n:is(.Editor-module_editor__hh8sg [data-editor-container='true']) .is-editor-empty:first-child::before {\n    pointer-events: none;\n    float: left;\n    height: 0px;\n    --tw-text-opacity: 1;\n    color: rgb(170 170 170 / var(--tw-text-opacity, 1));\n      content: attr(data-placeholder);\n}\n.Editor-module_editor__hh8sg [data-editor-tag='true'] {\n    display: contents;\n}\n";
var classes = {"editor":"Editor-module_editor__hh8sg"};
styleInject(css_248z);

export { classes as default };

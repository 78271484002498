import { Portal } from '@ark-ui/react'
import { ErrorData } from '@integration-app/sdk'
import { TbChevronDown } from 'react-icons/tb'

import { ErrorBadge, ErrorDocsLink } from 'components/Error/components'
import { Popover } from 'components/Popover'
import { StatusDot } from 'components/StatusDot'
import WorkspaceElementLink from 'components/WorkspaceElement/WorkspaceElementLink'
import { Badge, BadgeProps } from 'ui-kit/badge'
import { Icon } from 'ui-kit/icon'

import { ErrorItem } from './ErrorItem'
import { showErrorInPopup, useErrorPopupOpenStatus } from './ErrorPopup'
import {
  convertAnyErrorToErrorData,
  getErrorAdditionalData,
  getErrorDataWorkspaceElementReference,
  SupportedErrorTypes,
} from './helpers'

export function ErrorsBadge({
  title,
  errors,
  usePortal,
  useStatusDot = false,
  ...props
}: {
  title: string
  errors?: Array<SupportedErrorTypes>
  usePortal?: boolean
  useStatusDot?: boolean
} & BadgeProps) {
  const isErrorPopupOpen = useErrorPopupOpenStatus()
  if (!errors || errors.length === 0) return null
  const convertedErrors = errors.map(convertAnyErrorToErrorData)

  // When an error popup appears, we need to
  // hide a popover under the popup's overlay
  const popoverZIndex = isErrorPopupOpen ? 'overlay' : undefined

  return (
    <Popover.Root lazyMount unmountOnExit>
      <Popover.Trigger asChild>
        <Badge
          colorPalette='error'
          variant='subtle'
          cursor='pointer'
          {...props}
        >
          {useStatusDot && <StatusDot status='error' size='sm' />}
          {title}
          <Icon size={'sm'}>
            <TbChevronDown />
          </Icon>
        </Badge>
      </Popover.Trigger>
      <Portal disabled={!usePortal}>
        <Popover.Window width={'lg'} zIndex={popoverZIndex}>
          <Popover.Content>
            <ErrorsList
              errors={convertedErrors}
              onErrorClick={showErrorInPopup}
            />
          </Popover.Content>
        </Popover.Window>
      </Portal>
    </Popover.Root>
  )
}

function ErrorsList({
  errors,
  onErrorClick,
}: {
  errors: ErrorData[]
  onErrorClick: (error: ErrorData) => void
}) {
  return (
    <ErrorItem.List>
      {errors.map((error, i) => (
        <ErrorItem.Root key={i}>
          <ErrorItem.Header>
            <ErrorBadge error={error} />
            <ErrorItem.Title>{error.message}</ErrorItem.Title>
          </ErrorItem.Header>
          <ErrorJSONPreview error={error} onClick={() => onErrorClick(error)} />
          <ErrorWorkspaceElementReference error={error} />
          <ErrorDocsLink error={error} />
        </ErrorItem.Root>
      ))}
    </ErrorItem.List>
  )
}

function ErrorJSONPreview({
  error,
  onClick,
}: {
  error: ErrorData
  onClick: (error: ErrorData) => void
}) {
  const additionalData = getErrorAdditionalData(error)

  if (!additionalData) return null

  return (
    <ErrorItem.JSONPreview
      json={additionalData}
      tooltip={'Open popup with full error details'}
      onClick={() => onClick(error)}
    />
  )
}

function ErrorWorkspaceElementReference({ error }: { error: ErrorData }) {
  const wsElementRef = getErrorDataWorkspaceElementReference(error)

  if (!wsElementRef) return null
  return <WorkspaceElementLink wsElementRef={wsElementRef} />
}

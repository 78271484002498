"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useContext } from 'react';

const IntegrationAppClientContext = createContext(
  {
    /* FIXME: strictNullCheck temporary fix */
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Integration... Remove this comment to see the full error message
    client: null
  }
);
IntegrationAppClientContext.displayName = "IntegrationAppClientContext";
const IntegrationAppClientProvider = ({ client, children }) => {
  return /* @__PURE__ */ jsx(IntegrationAppClientContext.Provider, { value: { client }, children });
};
function useIntegrationAppClient() {
  return useContext(IntegrationAppClientContext);
}

export { IntegrationAppClientProvider, useIntegrationAppClient };

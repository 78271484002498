"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { useState, useEffect } from 'react';
import { resolveFormulas } from '@integration-app/sdk';
import { useIntegrationApp } from '../../contexts/integration-app-context.mjs';
import { useIntegrationAppConnection } from '../../contexts/integration-app-connection-context.mjs';

function useDynamicDataSchema(schema, value) {
  const integrationApp = useIntegrationApp();
  const { connectionId } = useIntegrationAppConnection();
  const [previousSourceSchema, setPreviousSourceSchema] = useState(void 0);
  const [resolvedSchema, setResolvedSchema] = useState(
    schema
  );
  const [error, setError] = useState(void 0);
  async function resolveSchema(schema2, value2) {
    try {
      const resolvedSchema2 = await resolveFormulas(schema2, {
        variables: await resolveFormulas(value2, {}),
        getDataCollection: connectionId ? async (key, parameters) => {
          const dataCollection = await integrationApp.connection(connectionId).dataCollection(key, parameters).get();
          return dataCollection;
        } : void 0
      });
      setResolvedSchema(resolvedSchema2);
      setError(void 0);
    } catch (err) {
      setError(err);
    }
  }
  useEffect(() => {
    if (schema !== previousSourceSchema) {
      setPreviousSourceSchema(schema);
      setResolvedSchema(schema);
    }
    if (schema) {
      void resolveSchema(schema, value);
    }
  }, [
    schema ? JSON.stringify(schema) : void 0,
    value ? JSON.stringify(value) : void 0,
    connectionId
  ]);
  return { schema: resolvedSchema, error };
}

export { useDynamicDataSchema };

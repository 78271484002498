"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { clsx } from 'clsx';
import { SvgIconType } from '../SvgIcon/svg-icon-type.mjs';
import useEventStopPropagation from '../../helpers/hooks/useEventStopPropagation.mjs';
import classes from './styles.module.css.mjs';
import { SvgIcon } from '../SvgIcon/index.mjs';

function TagSvgIcon({
  type,
  className,
  ...props
}) {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: clsx(classes.tagIcon, className),
      type,
      ...props
    }
  );
}
function TagImage({
  className,
  alt,
  ...props
}) {
  return /* @__PURE__ */ jsx(
    "img",
    {
      alt,
      className: clsx(classes.tagIcon, classes.tagImageIcon, className),
      ...props
    }
  );
}
function TagConfigButton({
  error = false,
  icon = SvgIconType.Settings,
  onClick
}) {
  return /* @__PURE__ */ jsx(
    "button",
    {
      type: "button",
      className: clsx(
        classes.tagConfigButton,
        error && classes.tagConfigButtonError
      ),
      onClick: useEventStopPropagation(onClick),
      children: /* @__PURE__ */ jsx(SvgIcon, { type: icon })
    }
  );
}

export { TagConfigButton, TagImage, TagSvgIcon };

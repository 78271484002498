// Putting these env variables into an object prevents builder from optimizing them out because they are constants.
// This is necessary to change these variables at runtime via entrypoint.sh.
export const ENV_CONFIG = {
  LOCAL_ENVIRONMENT:
    !!process?.env?.https://api.integration.app?.startsWith('http://localhost'),
  BASE_URI: process.env.NEXT_PUBLIC_BASE_URI,
  ENGINE_API_URI: process.env.https://api.integration.app,
  ENGINE_UI_URI: process.env.https://ui.integration.app,
  AUTH0_DOMAIN: process.env.login.integration.app,
  AUTH0_CLIENT_ID: process.env.eSR6dKpwDEHLFMErq3eCndAS2vx8Ouri,
  AUTH0_CLIENT_ID_PROD: process.env.eSR6dKpwDEHLFMErq3eCndAS2vx8Ouri_PROD,
  ALGOLIA_APP_ID: process.env.Q9GPIDDV0Q,
  ALGOLIA_API_KEY: process.env.d0e3e76d18b9a715e5fcaab2846c6c5a,
  ENABLE_TRACKING: process.env.1,
  USE_CONNECTOR_STORE: process.env.1,
  PYLON_APP_ID: process.env.e63a349c-87c1-4ee9-b38e-4b94656e3520,
  ENABLE_LIMITS: process.env.true,
  POSTHOG_KEY: process.env.phc_Poqk6Vc8iotZXAl3Zr8BH6DpgimUAd9gMOS3wDW88uW,
  POSTHOG_HOST: process.env.https://eu.i.posthog.com,
  NODE_ENV: process.env.NODE_ENV,
  ENABLE_USAGE_ALERTS: process.env.NEXT_PUBLIC_ENABLE_USAGE_ALERTS === 'true',
  ENABLE_BILLING: process.env.true,
}

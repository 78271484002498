"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { filterOptionsByLabel } from './utils.mjs';

class ConstantOptionFactory {
  constructor(options) {
    this.options = options;
  }
  async getOptions({
    input
  }) {
    return this.getOptionsSync({ input });
  }
  getOptionsSync({ input }) {
    return filterOptionsByLabel(this.options, input).map((option) => ({
      ...option,
      iconType: SvgIconType.ListOption
    }));
  }
}

export { ConstantOptionFactory };

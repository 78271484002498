"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { Formula, Variable, VariableSvgIcon } from '../../Formula/index.mjs';
import { DataBuilderForm } from '../Form.mjs';
import { makeDataField } from '../index.mjs';

const ExtractTimeFormula = forwardRef(
  (props, ref) => {
    return /* @__PURE__ */ jsx(Formula, { ref, onClick: props.onClick, children: /* @__PURE__ */ jsxs(Variable, { children: [
      /* @__PURE__ */ jsx(VariableSvgIcon, { type: SvgIconType.Function }),
      " Extract time"
    ] }) });
  }
);
function ExtractTimeFormulaDropdown(props) {
  const { onChange, variablesSchema, optionFactories, formula } = props;
  function handleVariableChange(value) {
    onChange?.({
      $extractTime: value
    });
  }
  const field = makeDataField({
    schema: {
      title: "Extract time from",
      type: "string",
      format: "date-time"
    },
    value: formula.value,
    variablesSchema,
    optionFactories
  });
  return /* @__PURE__ */ jsx(DataBuilderForm, { field, onChange: handleVariableChange });
}

export { ExtractTimeFormulaDropdown, ExtractTimeFormula as default };

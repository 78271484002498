"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

const simpleUniqueId = function(length = 6) {
  return Math.random().toString(36).substring(2, length + 2);
};

export { simpleUniqueId };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

function useEventStopPropagation(fn) {
  if (!fn) return void 0;
  return function(event) {
    const isClickOnLink = !!event.target.closest("a");
    if (!isClickOnLink) {
      event?.preventDefault?.();
    }
    event?.stopPropagation?.();
    event?.nativeEvent?.stopImmediatePropagation?.();
    if (typeof fn === "function") fn?.(event);
  };
}

export { useEventStopPropagation as default };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { BoundedPortal } from '../FloatingPortalBoundary/index.mjs';
import { Root, Trigger, Positioner, Content } from '../../ui-kit/styled/popover.mjs';

const DefaultPopper = forwardRef(
  ({
    placement = "bottom-start",
    isOpen,
    offset = [0, 8],
    className,
    children,
    triggerNode,
    onClickOutside
  }, ref) => {
    const [crossAxis, mainAxis] = offset;
    return /* @__PURE__ */ jsxs(
      Root,
      {
        open: isOpen,
        lazyMount: true,
        unmountOnExit: true,
        onPointerDownOutside: onClickOutside,
        positioning: {
          placement,
          offset: {
            crossAxis,
            mainAxis
          }
        },
        children: [
          triggerNode && /* @__PURE__ */ jsx(Trigger, { asChild: true, children: triggerNode }),
          /* @__PURE__ */ jsx(BoundedPortal, { children: /* @__PURE__ */ jsx(Positioner, { ref, children: /* @__PURE__ */ jsx(
            Content,
            {
              bg: "neutral.2",
              borderWidth: "thin",
              borderColor: "neutral.5",
              px: "4",
              py: "2",
              rounded: "sm",
              minW: "28",
              shadow: "none",
              className,
              children
            }
          ) }) })
        ]
      }
    );
  }
);

export { DefaultPopper };

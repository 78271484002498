"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { CATEGORY_COMPONENTS } from './categoryContentMapping.mjs';

function ActiveCategoryContent({
  onOptionSelect
}) {
  const { activeCategory } = useComboBoxDropdownContext();
  const CategoryContainer = (activeCategory ? CATEGORY_COMPONENTS[activeCategory] : void 0) ?? (() => /* @__PURE__ */ jsx("p", { children: "Unknown category" }));
  return /* @__PURE__ */ jsx(CategoryContainer, { onOptionSelect });
}

export { ActiveCategoryContent };

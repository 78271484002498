"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx, Fragment } from 'react/jsx-runtime';
import { useComboBoxDropdownContext } from '../../context/combobox-dropdown-context.mjs';
import { DropdownCategoryType } from '../../types.mjs';
import { DropdownUI } from '../../../DropdownUI/index.mjs';
import { OptionsList } from '../OptionsList.mjs';
import { LookupOptionsCategoryContent } from './LookupOptions/index.mjs';

function RootCategoryContent({
  onOptionSelect
}) {
  const { availableCategories } = useComboBoxDropdownContext();
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(DropdownUI.Panel, { children: availableCategories?.includes(DropdownCategoryType.LOOKUP_OPTIONS) ? /* @__PURE__ */ jsx(LookupOptionsCategoryContent, { onOptionSelect, children: /* @__PURE__ */ jsx(OptionsList, { onOptionSelect }) }) : /* @__PURE__ */ jsx(DropdownUI.List, { children: /* @__PURE__ */ jsx(OptionsList, { onOptionSelect }) }) }) });
}

export { RootCategoryContent };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useState, useRef, useEffect } from 'react';
import { getReferenceCollectionPointerForSchema, getMissingRequiredFields, extractIntegrationAppErrorData } from '@integration-app/sdk';
import deepEqual from 'fast-deep-equal';
import { DropdownUI } from '../../../../DropdownUI/index.mjs';
import { SvgIconType } from '../../../../SvgIcon/svg-icon-type.mjs';
import { useIntegrationAppClient } from '../../../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../../../contexts/integration-app-connection-context.mjs';
import { useIntegrationAppIntegration } from '../../../../../contexts/integration-app-integration-context.mjs';
import { useThrottle } from '../../../../../helpers/hooks/useThrottle.mjs';
import { simpleUniqueId } from '../../../../../helpers/simple-unique-id.mjs';
import { useDataCollectionSpec } from '../../../../../hooks/data-collections/useDataCollectionSpec.mjs';
import { css } from '../../../../../styled-system/css/css.mjs';
import '../../../../../styled-system/helpers.mjs';
import { sva } from '../../../../../styled-system/css/sva.mjs';
import '../../../../../styled-system/jsx/is-valid-prop.mjs';
import { Flex } from '../../../../../styled-system/jsx/flex.mjs';
import { Divider } from '../../../../../styled-system/jsx/divider.mjs';
import '../../../../../styled-system/patterns/visually-hidden.mjs';
import { Root, Icon, Title } from '../../../../../ui-kit/styled/alert.mjs';
import { ParametersPanel } from './ParametersPanel.mjs';
import { SvgIcon } from '../../../../SvgIcon/index.mjs';
import { useComboBox } from '../../../context/combobox-context.mjs';
import { useComboBoxDropdownContext } from '../../../context/combobox-dropdown-context.mjs';
import { useComboBoxDropdownSearchContext } from '../../../context/combobox-dropdown-search.mjs';
import { filterOptionsByLabel } from '../../../options-factories/utils.mjs';
import { useNavigationContext } from '../../KeyboardNavigation/NavigationContext.mjs';
import { useKeyboardBinding } from '../../KeyboardNavigation/useKeyboardBinding.mjs';
import DropdownOption from '../../Option.mjs';

const lookupOptionsRecipe = sva({
  className: "lookup-config-options",
  slots: [
    "container",
    "required-message-container",
    "required-message-title",
    "additional-message-container",
    "additional-message-title"
  ],
  base: {
    container: {
      flexDir: "column",
      maxW: "md",
      overflowY: "auto"
    },
    "required-message-container": {
      h: "fit-content",
      w: "fit-content",
      rounded: "lg",
      px: "3",
      py: "1.5",
      alignItems: "center",
      gap: "2",
      bg: "gray.2",
      mx: "1.5"
    },
    "required-message-title": {
      textStyle: "sm",
      color: "slate.11",
      fontWeight: "medium",
      padding: "0"
    },
    "additional-message-container": {
      h: "fit-content",
      w: "fit-content",
      rounded: "lg",
      px: "3",
      py: "1.5",
      alignItems: "center",
      gap: "2",
      bg: "warning.2",
      borderColor: "warning.8",
      mx: "1.5",
      mt: "1.5",
      "& svg": {
        color: "warning.11"
      }
    },
    "additional-message-title": {
      textStyle: "sm",
      color: "warning.11",
      fontWeight: "medium",
      padding: "0"
    }
  }
});
const styles = lookupOptionsRecipe();
function LookupOptionsCategoryContent({
  onOptionSelect,
  children
}) {
  const [options, setOptions] = useState([]);
  const [findByIdOption, setFindByIdOption] = useState();
  const [isLoading, setLoadingOptions] = useState(true);
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [error, setError] = useState();
  const { searchValue } = useComboBoxDropdownSearchContext();
  const { schema } = useComboBox();
  const throttledSearchValue = useThrottle(searchValue, 250);
  const pointer = getReferenceCollectionPointerForSchema(schema);
  const [parametersValue, setParametersValue] = useState(
    pointer?.parameters || {}
  );
  const { client } = useIntegrationAppClient();
  const { connectionId } = useIntegrationAppConnection();
  const { integrationId } = useIntegrationAppIntegration();
  const currentLoadOptionsId = useRef("");
  const referenceCollectionSpec = useDataCollectionSpec({
    key: pointer?.key,
    integrationId
  });
  useEffect(() => {
    if (pointer?.parameters) {
      setParametersValue(pointer.parameters);
    }
  }, [JSON.stringify(pointer)]);
  useEffect(() => {
    if (schema && connectionId) {
      void loadOptions(throttledSearchValue);
    }
  }, [
    throttledSearchValue,
    connectionId,
    JSON.stringify(schema),
    JSON.stringify(parametersValue),
    JSON.stringify(referenceCollectionSpec)
  ]);
  if (!schema) return null;
  const hasParameters = Object.keys(referenceCollectionSpec?.parametersSchema?.properties ?? {}).length > 0;
  const hasMissingParameters = getMissingRequiredFields(
    referenceCollectionSpec?.parametersSchema,
    parametersValue
  )?.length > 0;
  async function loadOptions(searchValue2) {
    const loadOptionsId = simpleUniqueId();
    currentLoadOptionsId.current = loadOptionsId;
    setLoadingOptions(true);
    setError(void 0);
    setFindByIdOption(void 0);
    setAdditionalMessage("");
    if (hasMissingParameters) return;
    try {
      if (!connectionId) {
        throw new Error("No connection provided. Cannot load options.");
      }
      if (!referenceCollectionSpec || !pointer) {
        setOptions([]);
        return;
      }
      const connectionAccessor = client.connection(connectionId);
      let response;
      const collectionAccessor = connectionAccessor.dataCollection(
        pointer?.key,
        parametersValue
      );
      if (referenceCollectionSpec.findById) {
        try {
          const itemById = await collectionAccessor.findById({
            id: searchValue2
          });
          setFindByIdOption({
            label: itemById.record.name ?? itemById.record.id,
            value: itemById.record.id,
            hint: itemById.record.id,
            hintPrefix: "ID:"
          });
        } catch {
        }
      }
      if (!referenceCollectionSpec?.list) {
        throw new Error(
          `Collection "${referenceCollectionSpec.key}" does not support listing options.`
        );
      }
      if (searchValue2 && referenceCollectionSpec.search) {
        try {
          response = await collectionAccessor.search({
            query: searchValue2
          });
          response.cursor = void 0;
        } catch {
        }
      }
      if (!searchValue2 && !response && referenceCollectionSpec?.list) {
        response = await collectionAccessor.list();
      }
      if (currentLoadOptionsId.current === loadOptionsId) {
        const options2 = response?.records.map((record) => {
          return {
            label: record.name ?? record.id,
            value: record.id,
            hint: record.id,
            hintPrefix: "ID:"
          };
        });
        if (response?.cursor && !!options2) {
          setAdditionalMessage(
            `Only the first ${options2.length} options are loaded. If your option is not in the list, please type its ID manually.`
          );
        }
        setOptions(options2 || []);
      }
    } catch (e) {
      if (currentLoadOptionsId.current === loadOptionsId) {
        setError(extractIntegrationAppErrorData(e));
      }
    } finally {
      if (currentLoadOptionsId.current === loadOptionsId) {
        setLoadingOptions(false);
      }
    }
  }
  const filteredOptions = filterOptionsByLabel(options, searchValue, true, [
    "value"
  ]);
  const aggregatedOptions = aggregateOptions(
    filteredOptions,
    searchValue,
    findByIdOption
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    hasParameters && /* @__PURE__ */ jsx(
      ParametersPanel,
      {
        onChange: (value) => setParametersValue(value),
        schema: referenceCollectionSpec?.parametersSchema,
        value: parametersValue
      }
    ),
    /* @__PURE__ */ jsx(Divider, { my: "1.5" }),
    /* @__PURE__ */ jsx(Flex, { className: styles.container, children: /* @__PURE__ */ jsxs(DropdownUI.List, { children: [
      children ? /* @__PURE__ */ jsxs(Fragment, { children: [
        children,
        /* @__PURE__ */ jsx(Divider, { my: "1.5" })
      ] }) : null,
      /* @__PURE__ */ jsx(DropdownUI.ListHeader, { children: "Available options" }),
      hasMissingParameters && /* @__PURE__ */ jsx(OptionsParametersRequiredAlert, {}),
      !hasMissingParameters && !error && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          LookupOptionsList,
          {
            fallbackOptions: options,
            options: aggregatedOptions,
            onOptionSelect: (value) => onOptionSelect(value?.value)
          }
        ),
        additionalMessage && /* @__PURE__ */ jsx(AdditionalMessageAlert, { text: additionalMessage })
      ] }),
      !hasMissingParameters && isLoading && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
        /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
        /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {})
      ] }),
      !hasMissingParameters && !isLoading && error && /* @__PURE__ */ jsx(
        DropdownUI.ErrorHeading,
        {
          text: error?.message || "Something went wrong"
        }
      )
    ] }) })
  ] });
}
function LookupOptionsList({
  options,
  fallbackOptions,
  onOptionSelect
}) {
  const { isOptionSelected } = useComboBoxDropdownContext();
  const { currentItem } = useNavigationContext(options, []);
  useKeyboardBinding({
    options,
    fallbackOptions
  });
  function handleOptionSelect(option) {
    onOptionSelect(option);
  }
  return /* @__PURE__ */ jsx(DropdownUI.List, { children: /* @__PURE__ */ jsx(
    DropdownListOptions,
    {
      fallbackOptions,
      options,
      currentItem,
      isOptionSelected,
      handleOptionSelect
    }
  ) });
}
function DropdownListOptions({
  options,
  fallbackOptions,
  currentItem,
  isOptionSelected,
  handleOptionSelect
}) {
  const items = options.length ? options : fallbackOptions;
  const isFallback = !options.length;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    isFallback && /* @__PURE__ */ jsx(DropdownUI.NoOptionsHeading, { text: "No options found" }),
    isFallback && /* @__PURE__ */ jsx("hr", { className: css({ my: 2 }) }),
    items.map((option, idx) => /* @__PURE__ */ jsx(
      DropdownOption,
      {
        option,
        selected: isOptionSelected(option),
        current: deepEqual(option, currentItem),
        onSelect: () => handleOptionSelect(option)
      },
      idx
    ))
  ] });
}
function OptionsParametersRequiredAlert() {
  const styles2 = lookupOptionsRecipe();
  return /* @__PURE__ */ jsxs(Root, { className: styles2["required-message-container"], children: [
    /* @__PURE__ */ jsx(Icon, { asChild: true, children: /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.InfoCircle }) }),
    /* @__PURE__ */ jsx(Title, { className: styles2["required-message-title"], children: "Please select parameters above to display lists" })
  ] });
}
function AdditionalMessageAlert({ text }) {
  const styles2 = lookupOptionsRecipe();
  return /* @__PURE__ */ jsxs(Root, { className: styles2["additional-message-container"], children: [
    /* @__PURE__ */ jsx(Icon, { asChild: true, children: /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.InfoCircle }) }),
    /* @__PURE__ */ jsx(Title, { className: styles2["additional-message-title"], children: text })
  ] });
}
function aggregateOptions(options, searchValue, findByIdOption) {
  const result = [];
  if (searchValue) {
    if (findByIdOption) {
      result.push(findByIdOption);
    } else {
      result.push({
        label: searchValue,
        value: searchValue,
        hint: "custom",
        hintPrefix: "ID:"
      });
    }
  }
  result.push(...options);
  return deduplicateOptions(result);
}
function deduplicateOptions(options) {
  const uniqueOptions = /* @__PURE__ */ new Set();
  return options.filter((option) => {
    if (uniqueOptions.has(option.value)) return false;
    uniqueOptions.add(option.value);
    return true;
  });
}

export { AdditionalMessageAlert, LookupOptionsCategoryContent, OptionsParametersRequiredAlert };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { getFormula, DataBuilderFormulaType, buildDataSchema, getReferenceCollectionPathForSchema, makeDataLocationOperationPath } from '@integration-app/sdk';
import usePromise from '../../../_modules/react-use-promise.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { useIntegrationAppClient } from '../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../contexts/integration-app-connection-context.mjs';
import { NestedLevel, Row, Cell, Title } from '../../DataBuilderUI/index.mjs';
import { makeSubField } from '../data-field.mjs';
import { FieldDelete } from '../FieldDelete.mjs';
import DataBuilderFormFieldValue from '../FieldValue/index.mjs';
import { DataBuilderFieldControlPanel } from './FieldControlPanel/index.mjs';

const MAX_EXPANDED_VALUES = 20;
function ValueMapping({ field, onChange }) {
  const { client } = useIntegrationAppClient();
  const { connectionId } = useIntegrationAppConnection();
  const formula = getFormula(field.value);
  if (formula?.type !== DataBuilderFormulaType.MAP) {
    return null;
  }
  const valueSchema = buildDataSchema(
    formula?.args?.value,
    field.variablesSchema
  );
  const [fieldRecords, fieldRecordsError] = usePromise(
    () => loadSchemaRecords(field.schema, client, connectionId),
    [JSON.stringify(field.schema)]
  );
  const [valueRecords, valueRecordsError] = usePromise(
    () => loadSchemaRecords(valueSchema, client, connectionId),
    [JSON.stringify(valueSchema)]
  );
  if (fieldRecordsError || valueRecordsError) {
    return /* @__PURE__ */ jsx("p", { children: (fieldRecordsError || valueRecordsError || "Unknown error").toString() });
  }
  if (!fieldRecords || !valueRecords) {
    return /* @__PURE__ */ jsx("p", { children: "Loading..." });
  }
  let autoExpandedValues = valueRecords;
  if (autoExpandedValues.length === 0 || autoExpandedValues.length > MAX_EXPANDED_VALUES) {
    autoExpandedValues = null;
  }
  return /* @__PURE__ */ jsx(Fragment, { children: autoExpandedValues ? /* @__PURE__ */ jsx(
    AutoExpandedMapping,
    {
      valueRecords: autoExpandedValues,
      fieldRecords,
      field,
      onChange
    }
  ) : /* @__PURE__ */ jsx(
    CustomMapping,
    {
      field,
      valueSchema,
      onChange
    }
  ) });
}
function AutoExpandedMapping({ valueRecords, field, fieldRecords, onChange }) {
  const mapping = field.value?.$map?.mapping ?? [];
  function handleMappingChange(fromRecord, toValue) {
    const newMapping = JSON.parse(JSON.stringify(mapping));
    let idx = newMapping.findIndex((m) => m.from === fromRecord.id);
    if (idx === -1) {
      idx = newMapping.length;
    }
    newMapping[idx] = {
      from: fromRecord.id,
      to: toValue
    };
    onChange(newMapping);
  }
  const fieldOptionsSchema = {
    type: field.schema?.type
  };
  if (fieldRecords?.length) {
    fieldOptionsSchema.referenceRecords = fieldRecords;
  }
  return /* @__PURE__ */ jsx(Fragment, { children: valueRecords.map((valueRecord, idx) => {
    const subField = makeSubField(field, {
      schema: fieldOptionsSchema,
      value: mapping.find((m) => m.from === valueRecord.id)?.to,
      optionFactories: [],
      variablesSchema: []
    });
    return /* @__PURE__ */ jsx(NestedLevel, { level: field.level + 1, children: /* @__PURE__ */ jsxs(Row, { children: [
      /* @__PURE__ */ jsx(Cell.Name, { grow: true, width: "49%", children: /* @__PURE__ */ jsx(Title, { childrenWrap: true, children: valueRecord.name ?? valueRecord.id }) }),
      /* @__PURE__ */ jsx(Cell.Icon, { type: SvgIconType.ValueMapping }),
      /* @__PURE__ */ jsx(Cell.Combobox, { grow: true, width: "49%", children: /* @__PURE__ */ jsx(
        DataBuilderFormFieldValue,
        {
          field: subField,
          onChange: (value) => handleMappingChange(valueRecord, value)
        }
      ) })
    ] }) }, idx);
  }) });
}
function CustomMapping({ field, valueSchema, onChange }) {
  const mapping = field.value?.$map?.mapping ?? [];
  function handleMappingItemChange(idx, value) {
    const newMapping = JSON.parse(JSON.stringify(mapping));
    newMapping[idx] = value;
    onChange(newMapping);
  }
  function handleAddMapping() {
    const newMapping = JSON.parse(JSON.stringify(mapping));
    newMapping.push({});
    onChange(newMapping);
  }
  function handleRemoveMapping(idx) {
    const newMapping = JSON.parse(JSON.stringify(mapping));
    newMapping.splice(idx, 1);
    onChange(newMapping);
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    mapping.map((mappingItem, idx) => /* @__PURE__ */ jsx(
      MappingListItem,
      {
        field,
        item: mappingItem,
        idx,
        valueSchema,
        onChange: (value) => handleMappingItemChange(idx, value),
        onDelete: () => handleRemoveMapping(idx)
      },
      idx
    )),
    /* @__PURE__ */ jsx(
      DataBuilderFieldControlPanel,
      {
        field,
        addFieldLabel: "Add Mapping",
        onAdd: handleAddMapping
      }
    )
  ] });
}
function MappingListItem({
  field,
  item,
  idx,
  valueSchema,
  onChange,
  onDelete
}) {
  const fromField = makeSubField(field, {
    valueLocator: `${field.valueLocator}.$map.mapping[${idx}].from`,
    schema: valueSchema,
    value: item?.from,
    optionFactories: [],
    variablesSchema: null
  });
  const toField = makeSubField(field, {
    valueLocator: `${field.valueLocator}.$map.mapping[${idx}].to`,
    schema: field.schema,
    value: item?.to,
    optionFactories: [],
    variablesSchema: null
  });
  function handleFromChange(value) {
    onChange({
      ...item ?? {},
      from: value
    });
  }
  function handleToChange(value) {
    onChange({
      ...item ?? {},
      to: value
    });
  }
  return /* @__PURE__ */ jsx(NestedLevel, { level: field.level + 1, children: /* @__PURE__ */ jsxs(Row, { children: [
    /* @__PURE__ */ jsx(Cell.Combobox, { grow: true, width: "49%", children: /* @__PURE__ */ jsx(
      DataBuilderFormFieldValue,
      {
        field: fromField,
        onChange: (value) => handleFromChange(value)
      }
    ) }),
    /* @__PURE__ */ jsx(Cell.Icon, { type: SvgIconType.ValueMapping }),
    /* @__PURE__ */ jsx(Cell.Combobox, { grow: true, width: "49%", children: /* @__PURE__ */ jsx(
      DataBuilderFormFieldValue,
      {
        field: toField,
        onChange: (value) => handleToChange(value)
      }
    ) }),
    /* @__PURE__ */ jsx(FieldDelete, { onClick: onDelete })
  ] }) });
}
async function loadSchemaRecords(schema, client, connectionId) {
  if (!schema) {
    return [];
  }
  if (schema.enum) {
    return schema.enum.map((value) => ({
      id: value
    }));
  } else if (schema.referenceRecords) {
    return schema.referenceRecords;
  } else if (getReferenceCollectionPathForSchema(schema) && client && connectionId) {
    const listResponse = await client.connection(connectionId).request(
      makeDataLocationOperationPath(
        /* FIXME: strictNullCheck temporary fix */
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not... Remove this comment to see the full error message
        getReferenceCollectionPathForSchema(schema),
        "list"
      )
    );
    return listResponse.records;
  } else {
    return [];
  }
}

export { ValueMapping };

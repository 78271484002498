"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { makeSubField } from '../data-field.mjs';
import classes from './ScalarArray.module.css.mjs';
import DataBuilderFormNestedArrayItems from '../Nested/ArrayItems.mjs';

function ScalarArrayFieldValue({
  field,
  onChange
}) {
  const currentValue = Array.isArray(field.value) ? field.value : [field.value];
  const dataBuilderField = makeSubField(field, {
    value: currentValue,
    level: 1
  });
  return /* @__PURE__ */ jsx("div", { className: classes.wrapper, children: /* @__PURE__ */ jsx(
    DataBuilderFormNestedArrayItems,
    {
      field: dataBuilderField,
      onChange
    }
  ) });
}

export { ScalarArrayFieldValue as default };

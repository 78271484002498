import { ComponentProps } from 'react'
import { default as NextRouterLink } from 'next/link'
import { useRouter } from 'next/router'

import { Link as UiLink, LinkProps as UiLinkProps } from 'ui-kit/link'

const nextRouterLinkProps = [
  'href',
  'replace',
  'scroll',
  'prefetch',
  'legacyBehavior',
  'passHref',
  'shallow',
  'locale',
] as const

type NRPropsKeys = (typeof nextRouterLinkProps)[number]
type NRProps = Pick<ComponentProps<typeof NextRouterLink>, NRPropsKeys>

export type NextLinkProps = UiLinkProps & NRProps

export function NextLink({ children, href, ...props }: NextLinkProps) {
  const router = useRouter()
  const isActive = router.pathname.startsWith(href)

  const [styleProps, linkProps] = splitLinkProps(props)
  return (
    <UiLink asChild {...styleProps} data-active={isActive ? 'true' : undefined}>
      <NextRouterLink href={href} {...linkProps}>
        {children}
      </NextRouterLink>
    </UiLink>
  )
}

function splitLinkProps(
  props: Partial<NRProps> & UiLinkProps,
): [styleProps: UiLinkProps, linkProps: Partial<NRProps>] {
  const styleProps = {}
  const linkProps = {}

  for (const key in props) {
    if (nextRouterLinkProps.includes(key as any)) {
      linkProps[key] = props[key]
    } else {
      styleProps[key] = props[key]
    }
  }

  return [styleProps, linkProps]
}

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import classes from './Radio.module.css.mjs';

const Radiobutton = forwardRef(
  ({ className, inline, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "input",
      {
        type: "radio",
        className: clsx(
          classes["uikit-radio"],
          inline && classes["uikit-radio__inline"],
          props?.checked && classes["uikit-radio__checked"],
          className
        ),
        ...props,
        ref
      }
    );
  }
);
Radiobutton.displayName = "Radiobutton";

export { Radiobutton };

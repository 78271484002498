import { useRouter } from 'next/router'

import { useWorkspace } from 'components/Workspaces/workspace-context'
import { LinkProps as UiLinkProps, Link as UiLink } from 'ui-kit/link'

import { NextLink } from './NextLink'

import { Link } from './index'

export type WorkspaceRelatedLinkProps = Omit<UiLinkProps, 'end'> & {
  to: string
}

export function WorkspaceRelatedLink({
  to,
  children,
  ...props
}: WorkspaceRelatedLinkProps) {
  const { route } = useRouter()
  const { workspace } = useWorkspace()

  const isCurrentRouteInWorkspace = route.startsWith('/w/[workspaceId]')

  // If the path is external, we should use a regular anchor tag
  if (to.startsWith('http://') || to.startsWith('https://')) {
    return (
      <UiLink href={to} {...props}>
        {children}
      </UiLink>
    )
  }

  const safePath = to.startsWith('/') ? to : `/${to}`

  // If the path is internal for the current workspace
  // render path as is
  if (isCurrentRouteInWorkspace) {
    return (
      <Link to={safePath} {...props}>
        {children}
      </Link>
    )
  }

  // This is next-router docs
  // We should add `/w/0` to the path if it's not there
  // for correct routing to the workspace
  const workspaceId = workspace?.id ?? 0
  const safeWorkspacePath = `/w/${workspaceId}${safePath}`

  return (
    <NextLink href={safeWorkspacePath} {...props}>
      {children}
    </NextLink>
  )
}

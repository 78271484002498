"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { makeDataField } from '../DataBuilder/index.mjs';
import { DataBuilderForm } from '../DataBuilder/Form.mjs';
import { useDynamicDataSchema } from './useResolvedDataSchema.mjs';

function DataInput({
  schema,
  variablesSchema,
  value,
  onChange,
  readOnly,
  editableVariablesSchemaLocators,
  onAddVariable,
  isAdminMode,
  errorFieldsLocators,
  frozenFieldsLocators,
  showNullValueFields,
  hideReadOnlyFields
}) {
  const { schema: resolvedSchema, error } = useDynamicDataSchema(schema, value);
  const field = makeDataField({
    schema: resolvedSchema,
    variablesSchema,
    value,
    resolveDynamicSchema: false
  });
  if (error) {
    return /* @__PURE__ */ jsx("div", { children: error.message });
  }
  return /* @__PURE__ */ jsx(
    DataBuilderForm,
    {
      field,
      isAdminMode,
      readOnly,
      onChange,
      editableVariablesSchemaLocators,
      onAddVariable,
      errorFieldsLocators,
      frozenFieldsLocators,
      showNullValueFields,
      hideReadOnlyFields
    }
  );
}

export { DataInput };

import { ComponentProps, ReactElement } from 'react'
import { RecipeVariantProps } from '@pandacss/types'

import { getDocRelativeLink } from 'components/Docs/DocLinksGrid/helpers'
import { Link } from 'components/Link'
import { getArticle } from 'routes/Docs/helpers/articleList'
import { sva, cx } from 'styled-system/css'
import { Icon } from 'ui-kit/icon'
import { Text, TextProps } from 'ui-kit/text'

const linkRecipe = sva({
  className: 'docLink',
  slots: ['root', 'icon'],
  base: {
    root: {
      colorPalette: 'primary',
      display: 'inline-flex',
      alignItems: 'center',
      lineHeight: 'none',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      gap: 0.5,
    },
    icon: {
      alignSelf: 'center',
    },
  },
})

export type DocLinkProps = Omit<ComponentProps<typeof Link>, 'to'> &
  RecipeVariantProps<typeof linkRecipe> & {
    path: string
    showTag?: boolean
    leftIcon?: ReactElement
    rightIcon?: ReactElement
  }

export default function DocLink({
  path,
  showTag,
  leftIcon,
  rightIcon,
  children,
  className,
  ...props
}: DocLinkProps) {
  const to = getDocRelativeLink(path)
  const linkRecipeClassnames = linkRecipe()

  return (
    <Link
      to={to}
      className={cx(linkRecipeClassnames.root, className)}
      {...props}
    >
      {!!leftIcon && (
        <Icon size={'sm'} className={linkRecipeClassnames.icon}>
          {leftIcon}
        </Icon>
      )}
      {children || getArticle(path)?.title}
      {!!rightIcon && (
        <Icon size={'sm'} className={linkRecipeClassnames.icon}>
          {rightIcon}
        </Icon>
      )}
      {showTag && <DocTagBadge />}
    </Link>
  )
}

function DocTagBadge(props: Omit<TextProps, 'children'>) {
  return (
    <Text
      as='span'
      backgroundColor='bg.muted'
      color='fg.muted'
      textStyle='xs'
      paddingInline='1'
      borderRadius='sm'
      _hover={{
        backgroundColor: 'primary.default',
        color: 'primary.fg',
      }}
      {...props}
    >
      docs
    </Text>
  )
}

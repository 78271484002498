"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useRef, useContext, useEffect, useState, useReducer } from 'react';
import deepEqual from 'fast-deep-equal';
import { useDeepEffect } from '../../../../helpers/hooks/useDeepEffect.mjs';
import { useComboBoxDropdownContext } from '../../context/combobox-dropdown-context.mjs';

const NavigationContext = createContext({
  currentItem: null,
  moveToNextItem: () => null,
  moveToPreviousItem: () => null,
  onOptionsListChange: () => null
});
function payloadReducer(state, action) {
  const compactState = Object.entries(state).reduce(
    (acc, [orderId, payload]) => {
      if (!payload.options.length && !payload.fallbackOptions.length) {
        return acc;
      }
      return {
        ...acc,
        [Number(orderId)]: payload
      };
    },
    {}
  );
  return {
    ...compactState,
    [action.orderId]: {
      options: action.options,
      fallbackOptions: action.fallbackOptions
    }
  };
}
function NavigationContextProvider({
  children
}) {
  const [currentItem, setCurrentItem] = useState(null);
  const [payload, dispatch] = useReducer(payloadReducer, {});
  const { isOptionSelected, drillDownStackTop } = useComboBoxDropdownContext();
  useDeepEffect(() => {
    const itemOptions = getUnifiedOptionsList(payload);
    if (!itemOptions.length) {
      return;
    }
    if (currentItem === "drill-up") {
      setCurrentItem(itemOptions[0]);
      return;
    }
    if (!currentItem || itemOptions.indexOf(currentItem) == -1) {
      setCurrentItem(itemOptions[0]);
      return;
    }
    if (getOptionIndex(currentItem, itemOptions) == -1) {
      const selectedOption = itemOptions.find(isOptionSelected);
      if (selectedOption) {
        setCurrentItem(selectedOption);
      } else {
        const onlyOptions = getOnlyItemOptions(itemOptions);
        setCurrentItem(onlyOptions[0]);
      }
    }
  }, [payload]);
  const moveToPreviousItem = () => {
    if (currentItem === "drill-up") {
      return;
    }
    const itemOptions = getUnifiedOptionsList(payload);
    if (!currentItem) {
      setCurrentItem(itemOptions[itemOptions.length - 1]);
      return;
    }
    const idx = getOptionIndex(currentItem, itemOptions);
    if (idx === 0 && !!drillDownStackTop) {
      setCurrentItem("drill-up");
      return;
    }
    if (idx === 0) {
      return;
    }
    if (idx > 0) {
      const currentOption = itemOptions[idx - 1];
      setCurrentItem(currentOption);
      return;
    }
    setCurrentItem(itemOptions[itemOptions.length - 1]);
  };
  const moveToNextItem = () => {
    const itemOptions = getUnifiedOptionsList(payload);
    if (itemOptions.length === 0) {
      setCurrentItem(null);
      return;
    }
    if (!currentItem || currentItem === "drill-up") {
      setCurrentItem(itemOptions[0]);
      return;
    }
    const idx = getOptionIndex(currentItem, itemOptions);
    if (idx === itemOptions.length - 1) {
      return;
    }
    if (idx === -1) {
      setCurrentItem(itemOptions[0]);
    }
    if (idx < itemOptions.length - 1) {
      const currentOption = itemOptions[idx + 1];
      setCurrentItem(currentOption);
    }
  };
  const onOptionsListChange = ({
    orderId,
    options,
    fallbackOptions
  }) => {
    dispatch({
      orderId,
      options,
      fallbackOptions
    });
  };
  return /* @__PURE__ */ jsx(
    NavigationContext.Provider,
    {
      value: {
        currentItem,
        moveToNextItem,
        moveToPreviousItem,
        onOptionsListChange
      },
      children
    }
  );
}
function useNavigationContext(options = [], fallbackOptions = []) {
  const orderId = useRef(Date.now());
  const {
    currentItem,
    moveToNextItem,
    moveToPreviousItem,
    onOptionsListChange
  } = useContext(NavigationContext);
  useDeepEffect(() => {
    onOptionsListChange({
      orderId: orderId.current,
      options,
      fallbackOptions
    });
  }, [options, fallbackOptions]);
  useEffect(() => {
    return () => {
      onOptionsListChange({
        orderId: orderId.current,
        options: [],
        fallbackOptions: []
      });
    };
  }, []);
  return {
    currentItem,
    moveToNextItem,
    moveToPreviousItem
  };
}
const makeOptionComparable = (option) => {
  return option && {
    ...option,
    label: option.searchLabel ?? option.label?.toString()
  };
};
const getOnlyItemOptions = (options) => options.filter((o) => !o.isSection);
function getOptionIndex(option, options) {
  return options.findIndex(
    (o) => deepEqual(makeOptionComparable(o), makeOptionComparable(option))
  );
}
const getUnifiedOptionsList = (payload) => {
  const { options, fallbackOptions } = Object.entries(payload).sort(([orderId]) => Number(orderId)).reduce(
    (acc, [_, { options: options2, fallbackOptions: fallbackOptions2 }]) => {
      return {
        options: [...acc.options, ...options2],
        fallbackOptions: [...acc.fallbackOptions, ...fallbackOptions2]
      };
    },
    {
      options: [],
      fallbackOptions: []
    }
  );
  const itemOptions = getOnlyItemOptions(options);
  return itemOptions.length ? itemOptions : getOnlyItemOptions(fallbackOptions);
};

export { NavigationContextProvider, useNavigationContext };

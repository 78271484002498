"use strict";
"use client";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { Switch } from '@ark-ui/react/switch';
export { SwitchContext as Context, SwitchHiddenInput as HiddenInput } from '@ark-ui/react/switch';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/conditions.mjs';
import '../../styled-system/css/css.mjs';
import { switchRecipe } from '../../styled-system/recipes/switch-recipe.mjs';
import { createStyleContext } from './utils/create-style-context.mjs';

const { withProvider, withContext } = createStyleContext(switchRecipe);
withProvider(Switch.RootProvider, "root");
const Root = withProvider(Switch.Root, "root");
const Control = withContext(Switch.Control, "control");
const Label = withContext(Switch.Label, "label");
const Thumb = withContext(Switch.Thumb, "thumb");

export { Control, Label, Root, Thumb };

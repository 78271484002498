"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import deepEqual from 'fast-deep-equal';
import { DataBuilderProvider } from './data-builder-context.mjs';
import DataBuilderFormField from './Field.mjs';
import classes from './styles.module.css.mjs';

function DataBuilderForm({
  field,
  isAdminMode,
  readOnly,
  onChange,
  errorFieldsLocators = [],
  frozenFieldsLocators = [],
  editableVariablesSchemaLocators = [],
  onAddVariable,
  showNullValueFields,
  hideReadOnlyFields,
  collapsed,
  fieldsToShow
}) {
  function handleOnChange(value) {
    if (!deepEqual(value, field.value)) {
      onChange(value);
    }
  }
  return /* @__PURE__ */ jsx(
    DataBuilderProvider,
    {
      topLevelField: field,
      isAdminMode,
      readOnly,
      hideReadOnlyFields,
      errorFieldsLocators,
      frozenFieldsLocators,
      showNullValueFields,
      editableVariablesSchemaLocators,
      onAddVariable,
      collapsedDataBuilder: collapsed,
      initialVisibleFieldCount: fieldsToShow,
      children: /* @__PURE__ */ jsx("div", { className: classes.wrapper, children: /* @__PURE__ */ jsx(DataBuilderFormField, { field, onChange: handleOnChange }) })
    }
  );
}

export { DataBuilderForm };

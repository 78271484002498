"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useFlowRun(id) {
  const {
    item: flowRun,
    archive,
    refresh,
    error,
    loading
  } = useElement(
    WorkspaceElementType.FlowRun,
    id,
    (integrationApp) => id ? integrationApp.flowRun(id) : void 0
  );
  return {
    flowRun,
    error,
    loading,
    refresh,
    archive
  };
}

export { useFlowRun };

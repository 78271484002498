"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import classes from './inputs.module.css.mjs';

const FieldWrapper = forwardRef(
  ({ children, disabled, className, requiredAndEmptyField, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "span",
      {
        className: clsx(
          classes.fieldWrapper,
          disabled && classes.fieldWrapper__disabled,
          !!props.onClick && !disabled && classes.fieldWrapper__clickable,
          !requiredAndEmptyField && classes.fieldWrapper__required,
          className
        ),
        ref,
        "data-role": "field-wrapper",
        "aria-disabled": disabled,
        "aria-readonly": disabled,
        ...props,
        children
      }
    );
  }
);

export { FieldWrapper };

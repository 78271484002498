"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useFlowInstance(selector) {
  const {
    item: flowInstance,
    accessor,
    refresh,
    ...rest
  } = useElement(
    WorkspaceElementType.FlowInstance,
    selector,
    (integrationApp) => selector ? integrationApp.flowInstance(selector) : void 0
  );
  async function enable() {
    await accessor?.enable();
    await refresh();
  }
  async function disable() {
    await accessor?.disable();
    await refresh();
  }
  async function reset(options) {
    await accessor?.reset(options);
    await refresh();
  }
  async function setup() {
    await accessor?.setup();
    await refresh();
  }
  async function openConfiguration(options) {
    return accessor?.openConfiguration(options);
  }
  async function openEditor(options = {}) {
    return accessor?.openEditor(options);
  }
  async function run(options = {}) {
    return accessor?.run(options);
  }
  async function startRun(options = {}) {
    return accessor?.startRun(options);
  }
  return {
    flowInstance,
    accessor,
    refresh,
    enable,
    disable,
    reset,
    setup,
    openConfiguration,
    openEditor,
    run,
    startRun,
    ...rest
  };
}

export { useFlowInstance };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef, useState, useEffect } from 'react';
import { clsx } from 'clsx';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Flex } from '../../styled-system/jsx/flex.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';
import { Skeleton } from '../../ui-kit/styled/skeleton.mjs';

const LoadingItem = forwardRef(({ className, delay = 300, ...props }, ref) => {
  const styles = dropdownRecipe();
  const [shouldShow, setShouldShow] = useState(delay === 0);
  useEffect(() => {
    if (delay !== 0) {
      const timer = setTimeout(() => {
        setShouldShow(true);
      }, delay);
      return () => clearTimeout(timer);
    }
    return () => {
    };
  }, []);
  if (!shouldShow) {
    return null;
  }
  return /* @__PURE__ */ jsxs(
    Flex,
    {
      className: clsx(styles["loading-item-container"], className),
      ...props,
      ref,
      children: [
        /* @__PURE__ */ jsx(Skeleton, { className: styles["loading-item-icon"] }),
        /* @__PURE__ */ jsx(Skeleton, { className: styles["loading-item-title"] })
      ]
    }
  );
});

export { LoadingItem };

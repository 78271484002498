/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { getPatternStyles, patternFns } from '../helpers.mjs';
import '../css/css.mjs';

const centerConfig = {
transform(props) {
  const { inline, ...rest } = props;
  return {
    display: inline ? "inline-flex" : "flex",
    alignItems: "center",
    justifyContent: "center",
    ...rest
  };
}};

const getCenterStyle = (styles = {}) => {
  const _styles = getPatternStyles(centerConfig, styles);
  return centerConfig.transform(_styles, patternFns)
};

export { getCenterStyle };

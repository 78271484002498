"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.12.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useContext } from 'react';
import { IntegrationElementLevel } from '@integration-app/sdk';
import { useIntegrationAppConnection, IntegrationAppConnectionProvider } from '../../contexts/integration-app-connection-context.mjs';
import { useIntegrationAppIntegration, IntegrationAppIntegrationProvider } from '../../contexts/integration-app-integration-context.mjs';

const IntegrationElementContext = createContext({});
function useIntegrationElementContext() {
  return useContext(IntegrationElementContext);
}
const IntegrationElementProvider = ({
  children,
  integrationId: integrationIdfromParams,
  connectionId: connectionIdFromParams
}) => {
  const { connectionId: connectionIdFromContext } = useIntegrationAppConnection();
  const { integrationId: integrationIdFromContext } = useIntegrationAppIntegration();
  const integrationId = integrationIdfromParams ?? integrationIdFromContext;
  const connectionId = connectionIdFromParams ?? connectionIdFromContext;
  const level = connectionId ? IntegrationElementLevel.CONNECTION : integrationId ? IntegrationElementLevel.CONNECTOR : IntegrationElementLevel.UNIVERSAL;
  return /* @__PURE__ */ jsx(
    IntegrationElementContext.Provider,
    {
      value: {
        integrationId,
        connectionId,
        level
      },
      children: /* @__PURE__ */ jsx(IntegrationAppIntegrationProvider, { integrationId, children: /* @__PURE__ */ jsx(IntegrationAppConnectionProvider, { connectionId, children }) })
    }
  );
};

export { IntegrationElementProvider, useIntegrationElementContext };
